<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }}
                      700KB</b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('FirstName')"
                label-for="register-firstName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="FirstName"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstName"
                    v-model="firstName"
                    name="register-firstName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('FirstName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- firstName -->
              <b-form-group
                :label="$t('LastName')"
                label-for="register-lastName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LastName"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastName"
                    v-model="lastName"
                    name="register-lastName"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('LastName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="dataId !== null"
              cols="12"
            >
              <!-- username -->
              <b-form-group
                :label="$t('Username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    :disabled="dataId == null ? false : true"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Username')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- phoneNumber -->
              <b-form-group
                :label="$t('PhoneNumber')"
                label-for="register-phoneNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="PhoneNumber"
                  vid="phoneNumber"
                  rules="required"
                >
                  <b-form-input
                    id="register-phoneNumber"
                    v-model="tel"
                    name="register-phoneNumber"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('PhoneNumber')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="specialUser">
                <b-form-checkbox
                  id="specialUser"
                  v-model="specialUser"
                  name="checkbox-1"
                >
                  {{ $t("Special User") }} ({{ $t('Admin menu can be seen in the application') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="priceShowUser">
                <b-form-checkbox
                  id="priceShowUser"
                  v-model="priceShowUser"
                  name="checkbox-1"
                >
                  {{ $t("Price Show User") }} ({{ $t('Administrator allows viewing of product prices') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group label-for="branchUser">
                <b-form-checkbox
                  id="branchUser"
                  v-model="branchUser"
                  name="checkbox-1"
                >
                  {{ $t("Branch User") }} ({{ $t('A menu for branch administrators can be seen in the application') }})
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="branchUser"
              cols="12"
            >
              <b-form-group
                :label="$t('Branch')"
                label-for="register-branch"
              >
                <v-select
                  id="register-branch"
                  v-model="branchId"
                  size="sm"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionBranch"
                  :reduce="OptionBranch => OptionBranch._id"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Member Group')"
                label-for="register-memberGroupId"
              >
                <v-select
                  id="register-memberGroupId"
                  v-model="memberGroupId"
                  size="sm"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionMemberGroup"
                  :reduce="OptionMemberGroup => OptionMemberGroup._id"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Member Level')"
                label-for="register-memberLevelId"
              >
                <v-select
                  id="register-memberLevelId"
                  v-model="memberLevelId"
                  size="sm"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionMemberLevel"
                  :reduce="OptionMemberLevel => OptionMemberLevel._id"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="dataId == null"
              cols="12"
            >
              <!-- password -->
              <b-form-group
                :label="$t('Password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  :rules="dataId == null ? 'required' : ''"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Invite Code')"
                label-for="register-inviteCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="inviteCode"
                  vid="inviteCode"
                  rules=""
                >
                  <b-form-input
                    id="register-inviteCode"
                    v-model="inviteCode"
                    name="register-inviteCode"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Invite Code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Employee Code')"
                label-for="register-employeeCode"
              >
                <validation-provider
                  #default="{ errors }"
                  name="employeeCode"
                  vid="employeeCode"
                  rules=""
                >
                  <b-form-input
                    id="register-employeeCode"
                    v-model="employeeCode"
                    name="register-employeeCode"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Employee Code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Status Type')"
                label-for="register-statusType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="statusType"
                  vid="statusType"
                  rules="required"
                >
                  <v-select
                    id="register-statusType"
                    v-model="statusType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionStatusType"
                    :reduce="(OptionStatusType) => OptionStatusType.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BFormCheckbox,
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameBranch: {
      type: String,
      required: true,
    },
    storeModuleNameMemberGroup: {
      type: String,
      required: true,
    },
    storeModuleNameMemberLevel: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      firstName: '',
      lastName: '',
      username: '',
      specialUser: false,
      priceShowUser: false,
      branchUser: false,
      branchId: 'onlineStore',
      statusType: 'pending',
      memberGroupId: null,
      memberLevelId: null,
      password: '',
      tel: '',
      inviteCode: '',
      employeeCode: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    OptionStatusType() {
      return [
        { name: this.$t('Pending'), value: 'pending' },
        { name: this.$t('Active'), value: 'active' },
        { name: this.$t('Suspend'), value: 'suspend' },
      ]
    },
    OptionBranch() {
      return store.state[this.storeModuleNameBranch].respData != null
        ? store.state[this.storeModuleNameBranch].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
    OptionMemberGroup() {
      return store.state[this.storeModuleNameMemberGroup].respData != null
        ? store.state[this.storeModuleNameMemberGroup].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
    OptionMemberLevel() {
      return store.state[this.storeModuleNameMemberLevel].respData != null
        ? store.state[this.storeModuleNameMemberLevel].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
  },
  watch: {
    isSidebarActive(val) {
      this.getWarehouseAndBranch()
      this.getMemberGroup()
      this.getMemberLevel()
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, image, firstName, lastName, userId, tel, inviteCode, employeeCode, statusType,
          } = this.data
          this.dataId = _id
          this.image = image
          this.firstName = firstName
          this.lastName = lastName
          this.username = userId.username
          this.specialUser = userId.specialUser
          this.priceShowUser = userId.priceShowUser
          this.branchUser = userId.branchUser
          if (userId.branchId) {
            this.branchId = userId.branchId
          }
          if (userId.memberGroupId) {
            this.memberGroupId = userId.memberGroupId
          }
          if (userId.memberLevelId) {
            this.memberLevelId = userId.memberLevelId
          }
          this.tel = tel
          this.inviteCode = inviteCode
          this.employeeCode = employeeCode
          this.statusType = statusType
        }
      }
    },
  },
  methods: {
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }

      store
        .dispatch(`${this.storeModuleNameMemberLevel}/get`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getMemberGroup() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }

      store
        .dispatch(`${this.storeModuleNameMemberGroup}/get`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getWarehouseAndBranch() {
      const obj = {
        currentPage: 0,
        pageSize: 20,
        searchQuery: '',
      }

      store
        .dispatch(`${this.storeModuleNameBranch}/get`, obj)
        .then(result => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 700KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.firstName = ''
      this.lastName = ''
      this.username = ''
      this.specialUser = false
      this.priceShowUser = false
      this.branchUser = false
      this.branchId = ''
      this.statusType = 'pending'
      this.memberGroupId = null
      this.memberLevelId = null
      this.password = ''
      this.tel = ''
      this.inviteCode = ''
      this.employeeCode = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            image: this.image,
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.username,
            specialUser: this.specialUser,
            priceShowUser: this.priceShowUser,
            branchUser: this.branchUser,
            branchId: this.branchId !== 'onlineStore' && this.branchId !== '' ? this.branchId : null,
            statusType: this.statusType,
            memberGroupId: this.memberGroupId !== '' ? this.memberGroupId : null,
            memberLevelId: this.memberLevelId !== '' ? this.memberLevelId : null,
            password: this.password,
            tel: this.tel,
            inviteCode: this.inviteCode,
            employeeCode: this.employeeCode,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              }).catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
