<template>
  <validation-observer
    ref="loginForm"
    #default="{ invalid }"
  >
    <b-modal
      :visible="isModalActive"
      :title="$t('Change Password')"
      ok-title="Accept"
      @change="(val) => $emit('update:is-modal-active', val)"
    >
      <b-overlay
        :show="dataLoading"
        rounded="sm"
      >
        <div class="d-flex justify-content-between">
          <label for="login-Passwd">{{ $t("Enter your password to verify your identity") }}</label>
        </div>
        <validation-provider
          #default="{ errors }"
          name="Passwd"
          vid="Passwd"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid' : null"
          >
            <b-form-input
              id="login-Passwd"
              v-model="passwd"
              :state="errors.length > 0 ? false : null"
              class="form-control-merge"
              :type="passwordFieldType"
              name="login-Passwd"
              :placeholder="$t('Enter your password to verify your identity')"
            />
            <b-input-group-append is-text>
              <feather-icon
                class="cursor-pointer"
                :icon="passwordToggleIcon"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div class="d-flex justify-content-between mt-2">

          <label for="login-Password">{{ $t("Enter the password to change") }}</label>
        </div>
        <validation-provider
          #default="{ errors }"
          name="Password"
          vid="password"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
            :class="errors.length > 0 ? 'is-invalid' : null"
          >
            <b-form-input
              id="login-Password"
              v-model="password"
              :state="errors.length > 0 ? false : null"
              class="form-control-merge"
              name="login-password"
              :placeholder="$t('Enter the password to change')"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left mb-0" />
          <div
            class="float-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              class="mr-2"
              :disabled="invalid"
              @click="submitData()"
            >
              {{ $t('Change') }}
              <feather-icon
                icon="ShoppingBagIcon"
                size="12"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class=""
              @click="$emit('update:is-modal-active', false)"
            >
              {{ $t('Close') }}
            </b-button>
          </div>

        </div>
      </template>

    </b-modal>
  </validation-observer>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,

  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      passwd: '',
      password: '',
      dataLoading: false,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.initValues()
        } else {
          const {
            _id,
          } = this.data
          this.dataId = _id
          this.passwd = ''
          this.password = ''
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.passwd = ''
      this.password = ''
    },
    submitData() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(`${this.$t('Do you want to change')}?`, {
              title: this.$t('Please Confirm'),
              cancelVariant: 'outline-secondary',
              okVariant: 'success',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
              centered: true,
            })
          // eslint-disable-next-line no-unused-vars
            .then(value => {
              if (value) {
                this.dataLoading = true
                store
                  .dispatch(`${this.storeModuleName}/updatePassword`, { id: this.dataId, passwd: this.passwd, password: this.password })
                // eslint-disable-next-line no-unused-vars
                  .then(result => {
                    this.dataLoading = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: this.$t('Success'),
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: this.$t(result.data.message),
                      },
                    })
                    this.initValues()
                    this.$emit('update:is-modal-active', false)
                  }).catch(error => {
                    this.dataLoading = false

                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                      },
                    })
                    // console.log('fetchUsers Error : ', error)
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                  })
              }

              //  else {
              //   this.showToast(
              //     'warning',
              //     'top-right',
              //     `${this.$t('DELETE_CANCEL')}!`,
              //     'AlertCircleIcon',
              //     this.$t('DELETE_CANCEL'),
              //   )
              // }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
