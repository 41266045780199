<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :store-module-name-branch="STORE_MODULE_NAME_BRANCH"
      :store-module-name-member-group="STORE_MODULE_NAME_MEMBER_GROUP"
      :store-module-name-member-level="STORE_MODULE_NAME_MEMBER_LEVEL"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <modal
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <modal-invite-code
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalInviteActive"
      :show.sync="show"
      :data="modalInviteData"
    />
    <modal-user-info
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalUserInfoActive"
      :show.sync="show"
      :data="modalUserInfoData"
    />
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t("entries") }}</label>
            </b-col>

            <b-col cols="12" md="6" class="b-col-custom">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex mr-2"
                  size="sm"
                  @click="importMember()"
                >
                  <span class="align-middle">{{ $t("Import") }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarAddNew()"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
          <b-form-group
            :label="`${$t('Update all member password')}:`"
            label-cols-sm="2"
            class="mt-1"
          >
            <div class="d-flex">
              <b-form-file
                class="d-flex mr-1"
                ref="refInputCSV"
                id="file-default"
                accept=".csv"
                :disabled="disableCSV"
                @change="inputCSVRenderer"
              ></b-form-file>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="d-flex"
                :disabled="disableCSV"
                @click="saveCSV()"
              >
                <feather-icon icon="SaveIcon" />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
            <div class=" mt-1">
            <label
              >{{ $t("Member") }} {{ valueMember }} / {{ maxMember }} ({{
                nameMember
              }})</label
            >
            <b-progress :max="maxMember">
              <b-progress-bar :value="valueMember">
                <strong>{{ valueMember }} / {{ maxMember }}</strong>
              </b-progress-bar>
            </b-progress>
          </div>
          </b-form-group>
    
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          style="height: 60vh;"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <template #cell(specialUser)="data">
            <feather-icon
              v-if="data.item.userId.specialUser === true"
              color="green"
              icon="CheckCircleIcon"
              size="18"
            />
            <feather-icon
              v-if="data.item.userId.specialUser === false"
              color="red"
              icon="XCircleIcon"
              size="18"
            />
          </template>
          <!-- Column: Images -->
          <template #cell(image)="data">
            <b-row>
              <b-img
                v-if="data.item.image"
                :src="data.item.image"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
            </b-row>
          </template>

          <template #cell(fullName)="data">
            <b-card-text
              >{{ data.item.firstName }} {{ data.item.lastName }}</b-card-text
            >
          </template>
          <!-- Column: Images -->
          <template #cell(referralCode)="data">
            <b-card-text v-if="data.item.userInfoInviteId">
              {{ data.item.userInfoInviteId.inviteCode }}
            </b-card-text>
          </template>
          <template #cell(delete)="data">
            <b-button
              v-if="data.item.delete === true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="d-flex"
              @click="rollbackData(data.item._id)"
            >
              <feather-icon icon="ClockIcon" />
              <!-- <span class="align-middle">Add Users</span> -->
            </b-button>
          </template>
          <template #cell(created)="data">
            <b-row>
              <p>{{ data.item.created | formatDateTime }}</p>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="modalUserInfoEdit(data.item)">
                <feather-icon icon="UserIcon" />
                <span class="align-middle ml-50">{{ $t("User Info") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$ability.can('read', 'E-commerce-mlm')"
                @click="inviteList(data.item._id)"
              >
                <feather-icon icon="UsersIcon" />
                <span class="align-middle ml-50">{{ $t("Invite list") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="modalEdit(data.item)">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">{{
                  $t("Change Password")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$ability.can('read', 'E-commerce-mlm')"
                @click="modalInviteCode(data.item)"
              >
                <feather-icon icon="UserCheckIcon" />
                <span class="align-middle ml-50">{{
                  $t("Referral code")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="sidebarEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BFormFile,
  BFormGroup,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import perPageOptions from "@/perPageOptions";
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
import storeModule from "@/store/services/eCommerce/member";
import storeModuleBranch from "@/store/services/eCommerce/branch";
import storeModuleMemberLevel from "@/store/services/eCommerce/memberLevel";
import storeModuleMemberGroup from "@/store/services/eCommerce/memberGroup";
import debounce from "debounce";
import Sidebar from "./DataSidebar.vue";
import Modal from "./Modal.vue";
import ModalInviteCode from "./ModalInviteCode.vue";
import ModalUserInfo from "./ModalUserInfo.vue";
import Papa from "papaparse";

const STORE_MODULE_NAME = "member";
const STORE_MODULE_NAME_BRANCH = "branch";
const STORE_MODULE_NAME_MEMBER_GROUP = "memberGroup";
const STORE_MODULE_NAME_MEMBER_LEVEL = "memberLevel";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    Modal,
    ModalInviteCode,
    ModalUserInfo,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BProgress,
    BProgressBar,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: "",
      perPage: localStorage.getItem("itemsPerPage")
        ? localStorage.getItem("itemsPerPage")
        : 30,
      isSidebarActive: false,
      isModalActive: false,
      isModalUserInfoActive: false,
      isModalInviteActive: false,
      sidebarData: {},
      modalData: {},
      modalUserInfoData: {},
      modalInviteData: {},
      show: false,
      lists: [],
      nameMember: "",
      valueMember: 0,
      maxMember: 0,
      disableCSV: false,
      perPageOptions,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: "specialUser", label: this.$t("Special User"), sortable: false },
        { key: "image", label: this.$t("Image"), sortable: false },
        { key: "userId.username", label: this.$t("Username"), sortable: false },
        { key: "employeeCode", label: this.$t("Employee Code"), sortable: false },
        { key: "inviteCode", label: this.$t("Invite Code"), sortable: false },
        {
          key: "referralCode",
          label: this.$t("Referral code"),
          sortable: false,
        },
        { key: "fullName", label: this.$t("FullName"), sortable: false },
        { key: "delete", label: this.$t("Roll back"), sortable: false },
        {
          key: "created",
          label: this.$t("Date of membership"),
          sortable: false,
        },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: "action", label: this.$t("ACTION"), sortable: false },
      ];
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : [];
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0;
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME];
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.get();
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);
    if (!store.hasModule(STORE_MODULE_NAME_BRANCH))
      store.registerModule(STORE_MODULE_NAME_BRANCH, storeModuleBranch);
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_GROUP))
      store.registerModule(
        STORE_MODULE_NAME_MEMBER_GROUP,
        storeModuleMemberGroup
      );
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL))
      store.registerModule(
        STORE_MODULE_NAME_MEMBER_LEVEL,
        storeModuleMemberLevel
      );
    this.get();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const applicationId = localStorage.getItem("applicationId");
    // WebSocket Connect
    const pathname = `/Members?type=${userData.userType}&userId=${userData.userId}&applicationId=${applicationId}`;
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    };
    store.dispatch("websockets/connect", params);
    this.typing = debounce(this.typing, 500);
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
    if (store.hasModule(STORE_MODULE_NAME_BRANCH))
      store.unregisterModule(STORE_MODULE_NAME_BRANCH);
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_GROUP))
      store.unregisterModule(STORE_MODULE_NAME_MEMBER_GROUP);
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL))
      store.unregisterModule(STORE_MODULE_NAME_MEMBER_LEVEL);
    store.dispatch("websockets/disconnect", STORE_MODULE_NAME);
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = (err) => {
        console.log("onError");
        console.log(err);
      };
      this.wsClient.onclose = (err) => {
        console.log("onClose");
        console.log(err);
      };
      this.wsClient.onmessage = (msg) => {
        const SocketMessage = JSON.parse(msg.data);
        //
        if (SocketMessage.data.send) {
          if (SocketMessage.data.send.data.message === "UPDATE_MEMBER") {
            this.disableCSV = true;
            this.nameMember = SocketMessage.data.send.data.data.nameMember;
            this.valueMember = SocketMessage.data.send.data.data.currentMember;
            this.maxMember = SocketMessage.data.send.data.data.maxMember;
            if (this.valueMember === this.maxMember) {
              this.disableCSV = false;
            }
          }
        }

        // this.adminOnline = SocketMessage.data.web.admin.length
        // this.rootOnline = SocketMessage.data.web.root.length
      };
    }
  },
  setup() {
    return {
      STORE_MODULE_NAME,
      STORE_MODULE_NAME_BRANCH,
      STORE_MODULE_NAME_MEMBER_GROUP,
      STORE_MODULE_NAME_MEMBER_LEVEL,
    };
  },
  methods: {
    saveCSV() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to save csv")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            // this.show = true;
            store
              .dispatch(`${STORE_MODULE_NAME}/updateMemberPassword`, {
                lists: this.lists,
              })
              .then((result) => {
                // this.show = false;
              })
              .catch((error) => {
                // this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    inputCSVRenderer(input) {
      if (input.target.files && input.target.files[0]) {
        // ตรวจสอบขนาดไฟล์
        if (input.target.files[0].size > 700000) {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: `${this.$t("Allowed")} CSV. ${this.$t(
                "Max size of"
              )} 700KB`,
            },
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const csvData = e.target.result;

          // ใช้ PapaParse เพื่อแปลง CSV เป็น JSON
          Papa.parse(csvData, {
            header: true, // ใช้แถวแรกเป็นคีย์ของอ็อบเจกต์ JSON
            dynamicTyping: false, // แปลงค่าข้อมูลให้เป็นประเภทข้อมูลที่เหมาะสม (เช่น ตัวเลข, บูลีน)
            skipEmptyLines: true, // ข้ามแถวที่ว่างเปล่า
            complete: (results) => {
              // console.log("Parsed CSV to JSON:", results.data);
              const data = results.data;
              let result = [];
              for (let index = 0; index < data.length; index++) {
                const item = data[index];
                const deletedUser = item.Username
                  .toString()
                  .split("_deleted");
                console.log(item.Username);
                if (
                  deletedUser.length == 1 &&
                  item.Username &&
                  item.EmpCode
                ) {
                  result.push({
                    ...item,
                    Username: item.Username.toString(),
                    EmpCode: item.EmpCode.toString(),
                  });
                }
              }
              this.lists = result;
              // คุณสามารถทำอะไรก็ได้กับข้อมูล JSON ที่ได้ที่นี่
              // เช่น ส่งไปยัง API หรือเก็บไว้ใน state
            },
            error: (error) => {
              console.error("Error parsing CSV:", error);
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Error"),
                  icon: "ErrorIcon",
                  variant: "danger",
                  text: this.$t("Failed to parse CSV file"),
                },
              });
            },
          });
        };

        reader.readAsText(input.target.files[0]);
      }
    },
    importMember() {
      this.$router.push({ name: "e-commerce-member-import" });
    },
    inviteList(id) {
      this.currentPage = 1;
      this.searchQuery = "";
      this.$router.push({
        query: {
          member: id,
        },
      });
    },
    modalUserInfoEdit(item) {
      this.modalUserInfoData = item;
      this.isModalUserInfoActive = true;
    },
    modalEdit(item) {
      this.modalData = item;
      this.isModalActive = true;
    },
    modalInviteCode(item) {
      this.modalInviteData = item;
      this.isModalInviteActive = true;
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        member: this.$route.query.member ? this.$route.query.member : "",
      };
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then((result) => {
          this.show = false;

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          this.show = false;
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    typing(value) {
      this.currentPage = 1;
      this.searchQuery = value;
      this.get();
    },
    sidebarAddNew() {
      this.sidebarData = {};
      this.isSidebarActive = true;
    },
    sidebarEdit(item) {
      this.sidebarData = item;
      this.isSidebarActive = true;
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to delete")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    rollbackData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to roll back")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            store
              .dispatch(`${STORE_MODULE_NAME}/rollback`, id)
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    pageSize(size) {
      localStorage.setItem("itemsPerPage", size);
      this.perPage = size;
      this.get();
    },
    nextPage(page) {
      this.currentPage = page;
      this.get();
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
